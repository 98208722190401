exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_footer_1JEJpiWQUTqJ74au_bPbbR {\n  position: absolute;\n  width: 100%;\n  left: 0\n}\n\n.IA_footerWeatherAndClockContainer_9GCUgdG5H2XeD0McXE-JU {\n  height: 100%;\n  display: flex;\n  flex-direction: row-reverse;\n}\n\n.IA_logo_2pcY_1POU0152QaOyYd0QS {\n  height: 100%;\n  width: 50%;\n  background-repeat: no-repeat;\n  background-size: contain;\n  position: relative;\n  float: left;\n}\n\n.IA_clock_3BCRjV-7Jjtkx0kzgrIVJX {\n  height: 100%;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-end;\n}\n\n.IA_time_1ciJnl44k81PggeWEAB0fO {\n  position: relative;\n  float: right;\n  text-align: left;\n  margin-left: 19%;\n  display: flex;\n  flex-direction: row;\n}\n\n.IA_timeDivider_5RKyIL90ipAsmpCzao1gv {\n  margin: 0 2px 0 2px;\n}\n\n.IA_date_4nNcWso8kE1WwwzdgmZv5 {\n  position: relative;\n  float: right;\n  text-align: right;\n  font-weight: lighter;\n}\n\n\n.IA_weather_16n6eJSNCnXn_f3a26QLke {\n  height: 100%;\n  position: relative;\n  float: right;\n  box-sizing: border-box;\n}\n\n.IA_weatherIcon_25FMQ4T6cFhmPAbp3exitf {\n  position: relative;\n  text-align: left;\n}\n\n.IA_weatherTemperature_7SvNo7VVTMVyFlkO4tKph {\n  position: relative;\n  float: right;\n  text-align: left;\n  width: 50%;\n\n}\n\n.IA_weatherArea_c0UQ5OwvS_hPX2xvaRTCc {\n  position: relative;\n  float: right;\n  text-align: left;\n  font-weight: lighter;\n  width: 50%;\n  clear: right;\n\n}", ""]);

// Exports
exports.locals = {
	"IA_footer": "IA_footer_1JEJpiWQUTqJ74au_bPbbR",
	"IA_footerWeatherAndClockContainer": "IA_footerWeatherAndClockContainer_9GCUgdG5H2XeD0McXE-JU",
	"IA_logo": "IA_logo_2pcY_1POU0152QaOyYd0QS",
	"IA_clock": "IA_clock_3BCRjV-7Jjtkx0kzgrIVJX",
	"IA_time": "IA_time_1ciJnl44k81PggeWEAB0fO",
	"IA_timeDivider": "IA_timeDivider_5RKyIL90ipAsmpCzao1gv",
	"IA_date": "IA_date_4nNcWso8kE1WwwzdgmZv5",
	"IA_weather": "IA_weather_16n6eJSNCnXn_f3a26QLke",
	"IA_weatherIcon": "IA_weatherIcon_25FMQ4T6cFhmPAbp3exitf",
	"IA_weatherTemperature": "IA_weatherTemperature_7SvNo7VVTMVyFlkO4tKph",
	"IA_weatherArea": "IA_weatherArea_c0UQ5OwvS_hPX2xvaRTCc"
};