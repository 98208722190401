exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_group_ny-yyCAzyWf4puUcI2Qjg {\n  width: 100%;\n  clear: both;\n  margin-bottom: 10px;\n  float: left;\n}\n\n.IA_line_vsVWMGtopAJEVGxmz9PPI {\n  width: calc(50% - 150px);\n  height: 1px;\n  background-color: #eeeeee;\n  float: left;\n  margin-top: 8px;\n}\n\n.IA_title_1l1h9YaMcLDp1pBSRycQNx {\n  font-size: 14px;\n  cursor: pointer;\n  font-weight: bold;\n  text-align: center;\n  float: left;\n}\n\n.IA_groupArea_3QPgj3ONUlyZJeivK7pRWk { \n  height: auto;\n  width: 100%;\n  box-sizing: border-box;\n  margin-top: 10px;\n  position: relative;\n  float: left;\n  margin-bottom: 10px;\n}", ""]);

// Exports
exports.locals = {
	"IA_group": "IA_group_ny-yyCAzyWf4puUcI2Qjg",
	"IA_line": "IA_line_vsVWMGtopAJEVGxmz9PPI",
	"IA_title": "IA_title_1l1h9YaMcLDp1pBSRycQNx",
	"IA_groupArea": "IA_groupArea_3QPgj3ONUlyZJeivK7pRWk"
};