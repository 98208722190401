exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_gallery_3KgdKmyqufTH5NqH620CJ3 {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n}\n\n.IA_image_bV8ATGoS7mzSsq0hnB-oK {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  background-size: cover;\n  background-position: center;\n}\n\n.IA_imageWithSlidingTransition_2OpbrzWJt1C6u64DsxRhle {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  float: left;\n  background-size: cover;\n  background-position: center;\n}\n\n.IA_thumbnails_DllDKO4mA3GLwCnfAhNnX {\n  width: 100%;\n  position: absolute;\n  bottom: 0;\n  background-color: rgb(51,51,51);\n}\n\n.IA_thumbnailsContainer_FeS9HkOB_nRUJJDOk05xv {\n  position: relative;\n  height: 100%;\n  width: max-content;\n  transition: margin-left 500ms ease-in-out;\n}\n\n.IA_thumbnail_10jMAApOhifbj-yUxkeVEW {\n  width: 100px;\n  height: 100px;\n  float: left;\n  background-size: cover;\n  background-position: center;\n  transition: opacity 500ms ease-in-out;\n}", ""]);

// Exports
exports.locals = {
	"IA_gallery": "IA_gallery_3KgdKmyqufTH5NqH620CJ3",
	"IA_image": "IA_image_bV8ATGoS7mzSsq0hnB-oK",
	"IA_imageWithSlidingTransition": "IA_imageWithSlidingTransition_2OpbrzWJt1C6u64DsxRhle",
	"IA_thumbnails": "IA_thumbnails_DllDKO4mA3GLwCnfAhNnX",
	"IA_thumbnailsContainer": "IA_thumbnailsContainer_FeS9HkOB_nRUJJDOk05xv",
	"IA_thumbnail": "IA_thumbnail_10jMAApOhifbj-yUxkeVEW"
};