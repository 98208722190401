exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".likesAndComments_2cRvkLjyn3Yy460DHbR4kz {\n  position: relative;\n  float: right;\n  font-size: 14px;\n  line-height: 20px;\n  margin-top: 8px;\n}\n\n.likesAndComments_2cRvkLjyn3Yy460DHbR4kz .likes_1rNGL6odutGKgrupODfkV_ {\n  height: 20px;\n  float: right;\n  position: relative;\n  cursor: pointer;\n}\n\n.likesAndComments_2cRvkLjyn3Yy460DHbR4kz .spinner_12dBc7PDU_NfiLh4ZlBtgX {\n  height: 14px;\n  width: 12px;\n  float: right;\n  position: relative;\n  margin-left: 2px;\n  -webkit-animation: spin_2Wu4iJbbf78wOYWbkHB04W 700ms linear infinite;\n  -moz-animation: spin_2Wu4iJbbf78wOYWbkHB04W 700ms linear infinite;\n  animation: spin_2Wu4iJbbf78wOYWbkHB04W 700ms linear infinite;\n}\n\n.likesAndComments_2cRvkLjyn3Yy460DHbR4kz .likesText_1Gp8sxiLHd314C7uZrkTnv {\n  float: left;\n  margin-left: 4px;\n  margin-top: -1px;\n}\n\n.likesAndComments_2cRvkLjyn3Yy460DHbR4kz .likesTextVertical_CrkfmqKwJox__2tLnv8bm {\n  float: left;\n  clear: both;\n  margin-top: -7px;\n  text-align: center;\n  width: 100%;\n  box-sizing: border-box;\n  padding-left: 1px;\n}\n\n@-moz-keyframes spin_2Wu4iJbbf78wOYWbkHB04W {\n  100% {\n    -moz-transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes spin_2Wu4iJbbf78wOYWbkHB04W {\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n@keyframes spin_2Wu4iJbbf78wOYWbkHB04W {\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"likesAndComments": "likesAndComments_2cRvkLjyn3Yy460DHbR4kz",
	"likes": "likes_1rNGL6odutGKgrupODfkV_",
	"spinner": "spinner_12dBc7PDU_NfiLh4ZlBtgX",
	"spin": "spin_2Wu4iJbbf78wOYWbkHB04W",
	"likesText": "likesText_1Gp8sxiLHd314C7uZrkTnv",
	"likesTextVertical": "likesTextVertical_CrkfmqKwJox__2tLnv8bm"
};