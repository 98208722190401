exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_cardFooter_zGp22DcIYqWOG8hyXTfNf {\n  height: 40px;\n  width: calc(100% - 20px);\n  box-sizing: border-box;\n  position: absolute;\n  left: 0;\n  bottom: 0;\n  margin: 0 10px;\n  border-top: 1px solid #eeeeee;\n}\n\n.IA_cardContainer_3ZtRwljD2UY1iKe7_P6QsM {\n  float: none;\n}\n\n.IA_cardBarBtn_1830yfIqpIwbLuoLPrAtdt {\n  float: right;\n  width: 20px;\n  height: 39px;\n  text-align: center;\n  cursor: pointer;\n  line-height: 40px;\n  border-radius: 5px;\n}\n\n.IA_cardBarBtn_1830yfIqpIwbLuoLPrAtdt:hover {\n  background-color: rgba(0, 0, 0, 0.10);\n}\n\nul.IA_cardBarItems_1cle2_9lMWO3AOzTITQlkj {\n  background-color: #FFF;\n  box-shadow: rgba(0, 0, 0, 0.2) 0 0 5px 0;\n  margin: 0;\n  padding: 0;\n  position: absolute;\n  z-index: 10000;\n  min-width: 180px;\n  right: 0;\n  bottom: 40px;\n  border-radius: 5px;\n}\n\n.IA_commandBarItem_3i2j0GkiEoaZ1GHKz8tpGG {\n  width: 100%;\n  list-style: none;\n  font-size: 12px;\n  padding: 8px;\n  cursor: pointer;\n  clear: both;\n  height: 30px;\n  box-sizing: border-box;\n}\n\n.IA_commandBarItem_3i2j0GkiEoaZ1GHKz8tpGG:hover {\n  background-color: #eeeeee;\n}\n\nul.IA_cardBarItems_1cle2_9lMWO3AOzTITQlkj li div {\n  display: inline-block;\n}\n\nul.IA_cardBarItems_1cle2_9lMWO3AOzTITQlkj li:hover {\n  background-color: rgba(0, 0, 0, 0.05);\n}\n\nul.IA_cardBarItems_1cle2_9lMWO3AOzTITQlkj li svg {\n  margin-right: 8px;\n  position: relative;\n  top: 2px;\n}\n\n.IA_cardFooterLeftText_1_50Df7S9sVQlfbQ0UNA7T {\n  float: left;\n  margin-top: 8px;\n  padding: 2px 5px;\n  border-radius: 5px;\n  background-color: rgba(0,0,0,0.1);\n}\n", ""]);

// Exports
exports.locals = {
	"IA_cardFooter": "IA_cardFooter_zGp22DcIYqWOG8hyXTfNf",
	"IA_cardContainer": "IA_cardContainer_3ZtRwljD2UY1iKe7_P6QsM",
	"IA_cardBarBtn": "IA_cardBarBtn_1830yfIqpIwbLuoLPrAtdt",
	"IA_cardBarItems": "IA_cardBarItems_1cle2_9lMWO3AOzTITQlkj",
	"IA_commandBarItem": "IA_commandBarItem_3i2j0GkiEoaZ1GHKz8tpGG",
	"IA_cardFooterLeftText": "IA_cardFooterLeftText_1_50Df7S9sVQlfbQ0UNA7T"
};