exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".likesAndComments_3pGl-ZrGKMUfoUt4Qw4M_d {\n  position: relative;\n  float: right;\n  height: 20px;\n  font-size: 14px;\n  line-height: 20px;\n  margin-top: 8px;\n}\n\n.likesAndComments_3pGl-ZrGKMUfoUt4Qw4M_d .likes_y6toO5c5WtdvnK68AAN1l {\n  height: 20px;\n  float: right;\n  position: relative;\n  margin-right: 5px;\n  cursor: pointer;\n}\n\n.likesAndComments_3pGl-ZrGKMUfoUt4Qw4M_d .spinner_1JN2sT9uQmmAuUy1XYbyfC {\n  height: 18px;\n  width: 18px;\n  float: right;\n  position: relative;\n  margin-left: 2px;\n  -webkit-animation: spin_2ngGhgD1zpW06jBn_w-0rK 700ms linear infinite;\n  -moz-animation: spin_2ngGhgD1zpW06jBn_w-0rK 700ms linear infinite;\n  animation: spin_2ngGhgD1zpW06jBn_w-0rK 700ms linear infinite;\n}\n\n.likesAndComments_3pGl-ZrGKMUfoUt4Qw4M_d .comments_EHNiqkjl02ffaCE1EJcEI {\n  height: 20px;\n  float: right;\n  position: relative;\n  margin-right: 3px;\n}\n\n.likesAndComments_3pGl-ZrGKMUfoUt4Qw4M_d .likesText_kbMtnkCgKo2mBUo2OsIMc {\n  float: left;\n  margin-left: 5px;\n  margin-top: -1px;\n}\n\n.likesAndComments_3pGl-ZrGKMUfoUt4Qw4M_d .likesTextVertical_1JFPRzjsXznwDYEsIvT_KR {\n  float: left;\n  clear: both;\n  margin-top: -7px;\n  text-align: center;\n  width: 100%;\n  box-sizing: border-box;\n  padding-left: 1px;\n}\n\n.likesAndComments_3pGl-ZrGKMUfoUt4Qw4M_d .commentText_4wvDlvINRnrWepD6zpaSg {\n  float: left;\n  margin-left: 4px;\n  margin-top: -2px;\n}\n\n@-moz-keyframes spin_2ngGhgD1zpW06jBn_w-0rK {\n  100% {\n    -moz-transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes spin_2ngGhgD1zpW06jBn_w-0rK {\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n@keyframes spin_2ngGhgD1zpW06jBn_w-0rK {\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"likesAndComments": "likesAndComments_3pGl-ZrGKMUfoUt4Qw4M_d",
	"likes": "likes_y6toO5c5WtdvnK68AAN1l",
	"spinner": "spinner_1JN2sT9uQmmAuUy1XYbyfC",
	"spin": "spin_2ngGhgD1zpW06jBn_w-0rK",
	"comments": "comments_EHNiqkjl02ffaCE1EJcEI",
	"likesText": "likesText_kbMtnkCgKo2mBUo2OsIMc",
	"likesTextVertical": "likesTextVertical_1JFPRzjsXznwDYEsIvT_KR",
	"commentText": "commentText_4wvDlvINRnrWepD6zpaSg"
};