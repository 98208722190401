exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_canteenMenuItem_XMZqvIHmnqf9dmslCxMNR {\n  width: 100%;\n  height: 100%;\n  position: relative;\n  overflow: hidden;\n}\n\n.IA_title_1guJhomFn--PNvNsuecrob {\n  float: left;\n  clear: both;\n  position: relative;\n  font-weight: bold;\n}\n\n.IA_contentWrapper_23a-6omR9SkoHBwCQidLDi {\n  float: left;\n  overflow: hidden;\n  width: 100%;\n  position: relative;\n}\n\n.IA_text_1mcP7hfgGaVVlTxWfpr2jx {\n  float: left;\n  clear: both;\n}\n\n.IA_text_1mcP7hfgGaVVlTxWfpr2jx p,\n.IA_text_1mcP7hfgGaVVlTxWfpr2jx ul {\n  margin: 0;\n}\n\n.IA_progressBarBackground_1k9F88bp4Y2U7pLalaNScH {\n  position: relative;\n  float: left;\n  clear: both;\n  position: relative;\n}\n\n.IA_startDate_1ze8BwCsXigJRcGQJiP-vJ {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 10px; \n  font-weight: lighter;\n}\n\n.IA_byline_3C8_KoG6jq11uXBGvJRELY {\n  float: left;\n  clear: both;\n  position: relative;\n}", ""]);

// Exports
exports.locals = {
	"IA_canteenMenuItem": "IA_canteenMenuItem_XMZqvIHmnqf9dmslCxMNR",
	"IA_title": "IA_title_1guJhomFn--PNvNsuecrob",
	"IA_contentWrapper": "IA_contentWrapper_23a-6omR9SkoHBwCQidLDi",
	"IA_text": "IA_text_1mcP7hfgGaVVlTxWfpr2jx",
	"IA_progressBarBackground": "IA_progressBarBackground_1k9F88bp4Y2U7pLalaNScH",
	"IA_startDate": "IA_startDate_1ze8BwCsXigJRcGQJiP-vJ",
	"IA_byline": "IA_byline_3C8_KoG6jq11uXBGvJRELY"
};