exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_spider_6FrPZJ6h-BZ59sOks-koZ {\n  position: absolute;\n  display: inline-block;\n  top: 0;\n  right: 10%;\n\n  /* 4. Animatie */\n  animation: swing_1mQhUQUQgWOmmBFR2VWYZ1 3s infinite;\n  transform-origin: top;\n\n  /* Bonus */\n  transition: 2s ease-in-out;\n}\n\n.IA_spiderWeb_K_JpvzfwDcZjklexI3EjT {\n  background: rgba(255, 255, 255, .7);\n}\n\n.IA_spiderBody_9y3er5q9tWnAq4RpwysDz {\n  position: relative;\n  background-image: url(\"https://intraactivestorage.blob.core.windows.net/cdn/play/spider.png\");\n  background-size: contain;\n  background-repeat: no-repeat;\n}\n\n.IA_spiderLeftEye_1oszgis4-WOsEXhjaVIv1p {\n  position: absolute;\n  background: #fff;\n  border-radius: 50%;\n}\n\n.IA_spiderLeftEye_1oszgis4-WOsEXhjaVIv1p:after {\n  background: red;\n  width: 32%;\n  height: 32%;\n  content: '';\n  display: block;\n  margin: 55%;\n  border-radius: 50%;\n\n  /* 3. Animatie */\n  animation: look_1B1nxxsKJB9A9mAJRoJ-hX 8s infinite;\n}\n\n.IA_spiderRightEye_-rIXSbktuihqU6dbT5D5g {\n  position: absolute;\n  background: #fff;\n  border-radius: 50%;\n}\n\n.IA_spiderRightEye_-rIXSbktuihqU6dbT5D5g:after {\n  background: red;\n  width: 32%;\n  height: 32%;\n  content: '';\n  display: block;\n  margin: 55%;\n  border-radius: 50%;\n\n  /* 3. Animatie */\n  animation: look_1B1nxxsKJB9A9mAJRoJ-hX 8s infinite;\n}\n\n/* 1. Animatie */\n@keyframes look_1B1nxxsKJB9A9mAJRoJ-hX {\n  0%, 40%, 100% {\n    transform: translateX(0);\n  }\n\n  45%, 95% {\n    transform: translateX(-110%);\n  }\n}\n\n\n/* 3. Animatie */\n@keyframes swing_1mQhUQUQgWOmmBFR2VWYZ1 {\n  0%, 100% {\n    transform: translateY(0);\n  }\n\n  50% {\n    transform: translateY(-20px);\n  }\n}", ""]);

// Exports
exports.locals = {
	"IA_spider": "IA_spider_6FrPZJ6h-BZ59sOks-koZ",
	"swing": "swing_1mQhUQUQgWOmmBFR2VWYZ1",
	"IA_spiderWeb": "IA_spiderWeb_K_JpvzfwDcZjklexI3EjT",
	"IA_spiderBody": "IA_spiderBody_9y3er5q9tWnAq4RpwysDz",
	"IA_spiderLeftEye": "IA_spiderLeftEye_1oszgis4-WOsEXhjaVIv1p",
	"look": "look_1B1nxxsKJB9A9mAJRoJ-hX",
	"IA_spiderRightEye": "IA_spiderRightEye_-rIXSbktuihqU6dbT5D5g"
};