exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_weatherWidgetContainer_EhU4Ln2eOL0oUP5aWfRsb {\n  height:100%;\n  width:100%;\n  display:flex;\n  flex-direction:row;\n}\n\n.IA_weatherDay_3-ZpCg7RAsVO1FjEvyB4eV {\n  flex:1;\n  display:flex;\n  flex-direction:column;\n  align-items:center;\n}\n\n.IA_iconAndPrecipationContainer_3M2xmGTfdqNaQF0nadu1z- {\n  width:100%;\n  display:flex;\n  flex-direction:column;\n  align-items:center;\n}\n\n.IA_weatherIcon_f2KQ5pKW-9nUo6NPeq7FN {\n  width: 45%;\n}\n\n.IA_precipation_17At-XaI20HMHTTC5SYeaZ {\n  color:#0062bf;\n  font-weight:bold;\n}\n\n.IA_graphContainer_2OFHFk1wcutJpRA1Gc5vTr {\n  width:100%;\n  height:30%;\n  position:absolute;\n  bottom:0;\n  left:0;\n}\n\n.IA_weatherWidgetContainerVertical_q1MSd33wKKQ3OeqAR4O7O {\n  height:100%;\n  width:100%;\n  background-color:#f0f2f4;\n  display:flex;\n  flex-direction: column;\n}\n\n.IA_weatherDayVertical_3yQH3_n1kQBpvPWOzwmJjc {\n  flex:1;\n  display:flex;\n  flex-direction:row;\n  align-items:center;\n  justify-content: space-around;\n}\n\n.IA_exampleWeatherLabel_1QxZNoxlKrikQcb5C4ewYf {\n  background-color: #ffffff;\n  color: #333333;\n  position: absolute;\n  text-align: center;\n  transform: rotate(-45deg);\n  box-shadow: rgba(0, 0, 0, 0.2) 0 0 5px 0;\n}", ""]);

// Exports
exports.locals = {
	"IA_weatherWidgetContainer": "IA_weatherWidgetContainer_EhU4Ln2eOL0oUP5aWfRsb",
	"IA_weatherDay": "IA_weatherDay_3-ZpCg7RAsVO1FjEvyB4eV",
	"IA_iconAndPrecipationContainer": "IA_iconAndPrecipationContainer_3M2xmGTfdqNaQF0nadu1z-",
	"IA_weatherIcon": "IA_weatherIcon_f2KQ5pKW-9nUo6NPeq7FN",
	"IA_precipation": "IA_precipation_17At-XaI20HMHTTC5SYeaZ",
	"IA_graphContainer": "IA_graphContainer_2OFHFk1wcutJpRA1Gc5vTr",
	"IA_weatherWidgetContainerVertical": "IA_weatherWidgetContainerVertical_q1MSd33wKKQ3OeqAR4O7O",
	"IA_weatherDayVertical": "IA_weatherDayVertical_3yQH3_n1kQBpvPWOzwmJjc",
	"IA_exampleWeatherLabel": "IA_exampleWeatherLabel_1QxZNoxlKrikQcb5C4ewYf"
};