exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_personaCard_1iP0JI7KEVEasJ249uqcu4{\n  display:flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.IA_personaWrapper_oRpXqjlyf9ERUaK5Gppmx {\n  font-size: 14px;\n  float: left;\n  display: block;\n  width: 100%;\n  padding: 8px 0;\n}\n\n.IA_personaWrapper_oRpXqjlyf9ERUaK5Gppmx .IA_personaProfileImageWrapper_hzQevy9TtP3PHykTsVNmL {\n  height: 30px;\n  width: 30px;\n  float: left;\n  border-radius: 30px;\n  margin-right: 7px;\n  margin-top: 1px;\n  font-size: 15px;\n  text-align: center;\n  line-height: 29px;\n  text-transform: uppercase;\n  font-weight: 200;\n}\n\n\n.IA_personaSmall_3dIDQgir8-B0xjAl9H9GHX .IA_personaProfileImageText_2z6soU4m2nkDYW4iM_iZrb {\n  height: 30px;\n  width: 30px;\n  border-radius: 30px;\n  left: 0px;\n  top: 0px;\n  position: relative;\n  float: left;\n}\n\n\n.IA_personaUserPrincipalName_3ehmbGaSoO8QfnjVd43T80 {\n  font-size: 9px;\n  opacity: 0.8\n}", ""]);

// Exports
exports.locals = {
	"IA_personaCard": "IA_personaCard_1iP0JI7KEVEasJ249uqcu4",
	"IA_personaWrapper": "IA_personaWrapper_oRpXqjlyf9ERUaK5Gppmx",
	"IA_personaProfileImageWrapper": "IA_personaProfileImageWrapper_hzQevy9TtP3PHykTsVNmL",
	"IA_personaSmall": "IA_personaSmall_3dIDQgir8-B0xjAl9H9GHX",
	"IA_personaProfileImageText": "IA_personaProfileImageText_2z6soU4m2nkDYW4iM_iZrb",
	"IA_personaUserPrincipalName": "IA_personaUserPrincipalName_3ehmbGaSoO8QfnjVd43T80"
};