exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_profile_1vuoWcZ5VFi2YovcLP1kI1 {\n  width: 100%;\n  margin-top: 20px;\n}\n\n.IA_divider_35Pg6-ijTMmYO00CCgqO_j, .IA_dividerDarkMode_1V_wGhyg4wKuyGO07UT3zN {\n  height: 1px;\n  width: 100%;\n  background-color: #eeeeee;\n  margin-top: 40px;\n  margin-bottom: 20px;\n  float: left;\n}\n\n.IA_dividerDarkMode_1V_wGhyg4wKuyGO07UT3zN {\n  background-color: #a8a8a8;\n}\n\n.IA_widgetType_pCtSMye5S6vk68Hg6dvIl {\n  margin-bottom: 15px;\n  width: 75px;\n  cursor: pointer;\n}\n\n.IA_widgetTypeLabel_3MAp1fiEpX2EJp5TcI5ecw {\n  font-size: 12px;\n  float: left;\n  clear: both;\n  width: 90%;\n  margin-top: 2px;\n}\n\n.IA_swatches_1Jd9R8o5t8_Lgq500XfEgX {\n  width: 100%;\n  clear: both;\n  position: relative;\n  float: left;\n}", ""]);

// Exports
exports.locals = {
	"IA_profile": "IA_profile_1vuoWcZ5VFi2YovcLP1kI1",
	"IA_divider": "IA_divider_35Pg6-ijTMmYO00CCgqO_j",
	"IA_dividerDarkMode": "IA_dividerDarkMode_1V_wGhyg4wKuyGO07UT3zN",
	"IA_widgetType": "IA_widgetType_pCtSMye5S6vk68Hg6dvIl",
	"IA_widgetTypeLabel": "IA_widgetTypeLabel_3MAp1fiEpX2EJp5TcI5ecw",
	"IA_swatches": "IA_swatches_1Jd9R8o5t8_Lgq500XfEgX"
};