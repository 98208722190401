exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".likesAndComments_c9weyyGo-uEYaIgTnp8ry {\n  position: relative;\n  float: right;\n  font-size: 14px;\n  line-height: 20px;\n}\n\n.likesAndComments_c9weyyGo-uEYaIgTnp8ry .likes_3m7SGkvZb5ou_kH1WrVSgN {\n  height: 20px;\n  float: right;\n  position: relative;\n}\n\n.likesAndComments_c9weyyGo-uEYaIgTnp8ry .spinner_2A2fn89_V0mZHt30DIWHgd {\n  height: 14px;\n  width: 12px;\n  float: right;\n  position: relative;\n  margin-left: 2px;\n  -webkit-animation: spin_2cDcOZznFo091qibDehZpj 700ms linear infinite;\n  -moz-animation: spin_2cDcOZznFo091qibDehZpj 700ms linear infinite;\n  animation: spin_2cDcOZznFo091qibDehZpj 700ms linear infinite;\n}\n\n.likesAndComments_c9weyyGo-uEYaIgTnp8ry .likesText_1c8r9LkFYzDwaQGamcAnMJ {\n  float: left;\n  margin-left: 4px;\n  margin-top: -1px;\n}\n\n.likesAndComments_c9weyyGo-uEYaIgTnp8ry .likesTextVertical_1gDh2bQLopcVFMasM4xw6 {\n  float: left;\n  clear: both;\n  margin-top: -7px;\n  text-align: center;\n  width: 100%;\n  box-sizing: border-box;\n  padding-left: 1px;\n}\n", ""]);

// Exports
exports.locals = {
	"likesAndComments": "likesAndComments_c9weyyGo-uEYaIgTnp8ry",
	"likes": "likes_3m7SGkvZb5ou_kH1WrVSgN",
	"spinner": "spinner_2A2fn89_V0mZHt30DIWHgd",
	"spin": "spin_2cDcOZznFo091qibDehZpj",
	"likesText": "likesText_1c8r9LkFYzDwaQGamcAnMJ",
	"likesTextVertical": "likesTextVertical_1gDh2bQLopcVFMasM4xw6"
};