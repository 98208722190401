exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_listElementHeader_3SK8WfGhj8yuuBeTiKz90Y {\n  width: 100%;\n  margin-bottom: 10px;\n  display: flex;\n  overflow: hidden;\n  font-size: 12px;\n  padding-left: 15px;\n  box-sizing: border-box;\n}\n\n.IA_previewColumn_3VspZLn45_HUyB4SI8PpfR {\n  flex: 0.2;\n}\n\n.IA_iconColumn_2RNlyh18iTffuRUQakrx3x {\n  flex: 0.4;\n  padding-right: 10px;\n  box-sizing: border-box;\n}\n\n.IA_dateColumn_2C8Dxdvi8JzVuh918HBJN7 {\n  flex: 0.5;\n}\n\n.IA_column_13JgBdGuiakC3XMulr4bh {\n  flex: 1;\n}\n\n.IA_buttons_3EqS6JbVcqg_zo9ZlZ82S4 {\n  padding-right: 10px;\n}", ""]);

// Exports
exports.locals = {
	"IA_listElementHeader": "IA_listElementHeader_3SK8WfGhj8yuuBeTiKz90Y",
	"IA_previewColumn": "IA_previewColumn_3VspZLn45_HUyB4SI8PpfR",
	"IA_iconColumn": "IA_iconColumn_2RNlyh18iTffuRUQakrx3x",
	"IA_dateColumn": "IA_dateColumn_2C8Dxdvi8JzVuh918HBJN7",
	"IA_column": "IA_column_13JgBdGuiakC3XMulr4bh",
	"IA_buttons": "IA_buttons_3EqS6JbVcqg_zo9ZlZ82S4"
};