exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_menuItem_2dXCS5O2tkTjdnWYwsQ0uu, .IA_menuItemDark_2quzFyJ9A8Q41n118QXiqn {\n  position: relative;\n  float: left;\n  height: 50px;\n  line-height: 50px;\n  width: 100%;\n  cursor: pointer;\n  box-sizing: border-box;\n  overflow: hidden;\n}\n\n.IA_menuItem_2dXCS5O2tkTjdnWYwsQ0uu:hover {\n  background-color: #eeeeee;\n}\n\n.IA_menuItemDark_2quzFyJ9A8Q41n118QXiqn:hover {\n  background-color: #414141;\n}\n\n.IA_menuItemIcon_1UeuyslrY7t0rZhIAsE9PC {\n  position: relative;\n  float: left;\n  height: 50px;\n  line-height: 50px;\n  width: 50px;\n  margin-left: -3px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.IA_menuItemText_21qr_dGhUV2Q6Iv5YaXLSA {\n  position: relative;\n  height: 50px;\n  line-height: 50px;\n  width: calc(100% - 50px);\n  box-sizing: border-box;\n}", ""]);

// Exports
exports.locals = {
	"IA_menuItem": "IA_menuItem_2dXCS5O2tkTjdnWYwsQ0uu",
	"IA_menuItemDark": "IA_menuItemDark_2quzFyJ9A8Q41n118QXiqn",
	"IA_menuItemIcon": "IA_menuItemIcon_1UeuyslrY7t0rZhIAsE9PC",
	"IA_menuItemText": "IA_menuItemText_21qr_dGhUV2Q6Iv5YaXLSA"
};