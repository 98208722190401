exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_license_kd-w5IwLuzdFlmQltC0zi {\n  width: 100%;\n  margin-top: 20px;\n}\n\n.IA_licenseSlider_1pEgNTaNwaHhxxvzvAT8XI {\n  -webkit-appearance: none;\n  width: 100%;\n  height: 5px;\n  background: #ffc885;\n  outline: none;\n  -webkit-transition: .2s;\n  transition: opacity .2s;\n  border-radius: 5px;\n}\n\n.IA_licenseSlider_1pEgNTaNwaHhxxvzvAT8XI::-webkit-slider-thumb {\n  -webkit-appearance: none;\n  appearance: none;\n  width: 25px;\n  height: 25px;\n  border-radius: 25px;\n  background: #ffffff;\n  cursor: pointer;\n}\n\n.IA_licenseSlider_1pEgNTaNwaHhxxvzvAT8XI::-moz-range-thumb {\n  width: 25px;\n  height: 25px;\n  border-radius: 25px;\n  background: #ffffff;\n  cursor: pointer;\n}\n\n.IA_licenseLabel_3RYFeYiBFkhHmpynOsawj- {\n  font-size: 90px;\n  text-align: center;\n  width: 100%;\n  clear: both;\n  float: left;\n}\n\n.IA_licensePrize_1V1S7YkLeatCNY_GJK0-_x {\n  font-size: 16px;\n  text-align: center;\n  margin-top: 20px;\n}\n\n.IA_licenseBox_3tE5xb4UT3pStFcaYOgVtA {\n  background-color: #fc8a01;\n  border-radius: 10px;\n  margin-top: 30px;\n  padding: 20px 40px;\n  text-align: left;\n  color: #ffffff;\n  position: relative;\n  float: left;\n  clear: both;\n  width: 100%;\n  box-sizing: border-box;\n}\n\n.IA_licenseBox_3tE5xb4UT3pStFcaYOgVtA h1 {\n  float: left;\n}\n\n.IA_pivotContent_Dmiq15GOIbhCL5AjPHLIO {\n  width: 100%;\n  float: left;\n  overflow: hidden;\n}", ""]);

// Exports
exports.locals = {
	"IA_license": "IA_license_kd-w5IwLuzdFlmQltC0zi",
	"IA_licenseSlider": "IA_licenseSlider_1pEgNTaNwaHhxxvzvAT8XI",
	"IA_licenseLabel": "IA_licenseLabel_3RYFeYiBFkhHmpynOsawj-",
	"IA_licensePrize": "IA_licensePrize_1V1S7YkLeatCNY_GJK0-_x",
	"IA_licenseBox": "IA_licenseBox_3tE5xb4UT3pStFcaYOgVtA",
	"IA_pivotContent": "IA_pivotContent_Dmiq15GOIbhCL5AjPHLIO"
};