exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_rotator_2HBg0M2Ypbka6E8e1iYX1Q {\n  width: 100%;\n  height: 100%;\n  transition: left 500ms ease-in-out;\n  position: absolute;\n}\n\n.IA_dots_1sXSQDw0V-txqPCCz23Ru8 {\n  position: absolute;\n  z-index: 1000;\n  width: 105px;\n  background-color: #ffffff;\n  border-radius: 5px;\n  box-shadow: 0 1px 1px 0 #888888;\n  right: 20px;\n  top: 20px;\n  z-index: 3000000;\n}\n\n.IA_dot_2rOXV2_X4Xyw8XC9e9RKdq {\n  position: relative;\n  float: left;\n  cursor: pointer;\n}\n\n.IA_image_3Dmr8gtukjNRB6H7j7Hy2w {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  background-size: cover;\n  background-position: center;\n}\n\n.IA_imageWithSlidingTransition_H2Mr0DeNxNb68R9B8auKg {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  float: left;\n  background-size: cover;\n  background-position: center;\n  overflow: hidden;\n}", ""]);

// Exports
exports.locals = {
	"IA_rotator": "IA_rotator_2HBg0M2Ypbka6E8e1iYX1Q",
	"IA_dots": "IA_dots_1sXSQDw0V-txqPCCz23Ru8",
	"IA_dot": "IA_dot_2rOXV2_X4Xyw8XC9e9RKdq",
	"IA_image": "IA_image_3Dmr8gtukjNRB6H7j7Hy2w",
	"IA_imageWithSlidingTransition": "IA_imageWithSlidingTransition_H2Mr0DeNxNb68R9B8auKg"
};