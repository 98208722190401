exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_likesAndComments_51aDJdHuT5xoPdzsXy-3D {\n  float: left;\n  width: 100%;\n  font-size: 12px;\n  line-height: 20px;\n  margin-top: auto;\n  padding-bottom: 10px;\n  background-color: #f6f6f6;\n  margin-bottom: 20px;\n  box-sizing: border-box;\n}\n\n.IA_commandBar_3dtoCx66JDqThVVD77lt9T {\n  width: 100%;\n  height: 30px;\n  float: left;\n  box-sizing: border-box;\n  line-height: 30px;\n}\n\n.IA_commandBarButton_1nJrRBFwpI78JHEVh-9h8l {\n  min-width: 40px;\n  padding-right: 10px;\n  float: left;\n  cursor: pointer;\n}\n\n.IA_commandBarButtonIcon_bGKjzkW2Yji4etEfmr5a {\n  margin-right: 5px;\n  margin-top: 1px;\n  float: left;\n}\n\n.IA_commandBarButtonText_34bdwo37GeJoTlf7bNKMKl {\n  float: left;\n  width: auto;\n  font-size: 14px;\n}\n\n.IA_comments_2h-LRRuHg7ndcb8E4Tk1_0 {\n  width: 100%;\n  height: auto;\n  box-sizing: border-box;\n  clear: both;\n}\n\n.IA_likes_2wwsdWZs6gpzJ-wfATQxw- {\n  width: 100%;\n  box-sizing: border-box;\n}\n\n.IA_spinner_3UB_UChuKvE-ZGtG5cQZFo {\n  -webkit-animation: spin_3frplEcs0Og-seSGZ-88TL 700ms linear infinite;\n  -moz-animation: spin_3frplEcs0Og-seSGZ-88TL 700ms linear infinite;\n  animation: spin_3frplEcs0Og-seSGZ-88TL 700ms linear infinite;\n  height: 15px;\n  width: 15px;\n  background-position: center;\n  background-size: 15px;\n  position: relative;\n  float: left;\n  clear: both;\n  opacity: 0.5;\n}\n\n.IA_personWhoLiked_wlu8iB-8JelUPCTevbyKw {\n  padding-bottom: 5px;\n  padding-top: 5px;\n  border-bottom: 1px solid #eeeeee;\n  width: calc(100% - 15px);\n}\n\n@-moz-keyframes spin_3frplEcs0Og-seSGZ-88TL {\n  100% {\n    -moz-transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes spin_3frplEcs0Og-seSGZ-88TL {\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n@keyframes spin_3frplEcs0Og-seSGZ-88TL {\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"IA_likesAndComments": "IA_likesAndComments_51aDJdHuT5xoPdzsXy-3D",
	"IA_commandBar": "IA_commandBar_3dtoCx66JDqThVVD77lt9T",
	"IA_commandBarButton": "IA_commandBarButton_1nJrRBFwpI78JHEVh-9h8l",
	"IA_commandBarButtonIcon": "IA_commandBarButtonIcon_bGKjzkW2Yji4etEfmr5a",
	"IA_commandBarButtonText": "IA_commandBarButtonText_34bdwo37GeJoTlf7bNKMKl",
	"IA_comments": "IA_comments_2h-LRRuHg7ndcb8E4Tk1_0",
	"IA_likes": "IA_likes_2wwsdWZs6gpzJ-wfATQxw-",
	"IA_spinner": "IA_spinner_3UB_UChuKvE-ZGtG5cQZFo",
	"spin": "spin_3frplEcs0Og-seSGZ-88TL",
	"IA_personWhoLiked": "IA_personWhoLiked_wlu8iB-8JelUPCTevbyKw"
};