exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_mediaFiles_K82URZ_MerCtlus5TQYbI {\n  position: relative;\n  width: 100%;\n  height: auto;\n  float: left;\n}\n\n.IA_filters_fLL0FXmv6J9ZWTTA9MF5C {\n  width: 100%;\n  margin-bottom: 10px;\n  display: flex;\n  flex-direction: row;\n}\n\n.IA_paginationButtonContainer_X9zxgHUtlSDdcb6FOeobN {\n  width: 100%;\n  height: 30px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.IA_paginationPageCount_FoR_orLmDcLQkuuG4ayMt {\n  text-align: center;\n}\n\n.IA_tagButton_tZEWuTiwipudXKyVRDWwK {\n  padding: 5px 7px;\n  margin: 5px;\n  border-radius: 5px;\n  cursor: pointer;\n  user-select: none;\n  display: inline-block;\n  font-size: 13px;\n}\n\n.IA_tagButtonWithDelete_2KlU6R3n_GGA6XLsYremZT {\n  margin: 5px;\n  border-radius: 5px;\n  cursor: pointer;\n  user-select: none;\n  display: inline-block;\n  font-size: 13px;\n  overflow: hidden;\n}\n\n.IA_tagButtonFlexContainer_3yLzYIIZqiPqXEWC_dUKzN {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n}\n\n.IA_tagButtonTagName_3Fr2yEpgXKR9gEhaael3Kp {\n  padding: 3px 7px 5px;\n}\n\n.IA_tagButtonIcon_1C_hgSFxCszXqGUJzS4J_D {\n  padding: 3px 3px 5px;\n}\n\n.IA_tagButtonIcon_1C_hgSFxCszXqGUJzS4J_D:hover{\n  background-color: rgba(0,0,0,0.2);\n}", ""]);

// Exports
exports.locals = {
	"IA_mediaFiles": "IA_mediaFiles_K82URZ_MerCtlus5TQYbI",
	"IA_filters": "IA_filters_fLL0FXmv6J9ZWTTA9MF5C",
	"IA_paginationButtonContainer": "IA_paginationButtonContainer_X9zxgHUtlSDdcb6FOeobN",
	"IA_paginationPageCount": "IA_paginationPageCount_FoR_orLmDcLQkuuG4ayMt",
	"IA_tagButton": "IA_tagButton_tZEWuTiwipudXKyVRDWwK",
	"IA_tagButtonWithDelete": "IA_tagButtonWithDelete_2KlU6R3n_GGA6XLsYremZT",
	"IA_tagButtonFlexContainer": "IA_tagButtonFlexContainer_3yLzYIIZqiPqXEWC_dUKzN",
	"IA_tagButtonTagName": "IA_tagButtonTagName_3Fr2yEpgXKR9gEhaael3Kp",
	"IA_tagButtonIcon": "IA_tagButtonIcon_1C_hgSFxCszXqGUJzS4J_D"
};