exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Droid+Sans+Mono);", ""]);

// Module
exports.push([module.id, ".IA_counterBackground_s38s_gyyPB_jtSN8DYkoB {\n  background-color: #4b234a;\n  background-size: cover;\n  background-position: center;\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n\n.IA_counterBackgroundOverlay_3B0FC42TAeQVp1TrlsSwzM {\n  background-color: #4b234a;\n  background-size: cover;\n  background-position: center;\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n\n.IA_content_3nte2kHjxm8pIqCnFonFMk {\n  width: 100%;\n  position: absolute;\n  color: #ffffff;\n  font-family: \"Segoe UI\", \"Segoe UI Web (West European)\", \"Segoe UI\", -apple-system, BlinkMacSystemFont, Roboto, \"Helvetica Neue\", sans-serif;\n}\n\n.IA_headline_1b7Y1MgD9T9X3vBcq5owxE {\n  width: 100%;\n  text-align: center;\n  box-sizing: border-box;\n}\n\n.IA_description_2FEaKlK9WVT1tKOIb3UM4p {\n  width: 100%;\n  text-align: center;\n  box-sizing: border-box;\n}\n\n.IA_counter_3pqTAFc3f59tthmNSiM9g4 {\n  display: flex;\n  flex-wrap: nowrap;\n  text-align: center;\n}\n\n.IA_days_1ylfueJOkfBCNXRcSusm4T {\n  text-align: center;\n  flex-direction: row;\n}\n\n.IA_hours_rVtseAw-b4sZanQDEFGmQ {\n  text-align: center;\n  flex-direction: row;\n}\n\n.IA_minuts_1BXrjrU0nLXGDqRNhPMpur {\n  text-align: center;\n  flex-direction: row;\n}\n\n.IA_seconds_3-C28p54uY-058VvxYsMgo {\n  text-align: center;\n  flex-direction: row;\n}\n\n.IA_number_cC8LftzTZQr7hb4sWO6T8 {\n  width: 100%;\n  font-family: \"Droid Sans Mono\", monospace;\n}\n\n.IA_numberInfo_2qaZiFHt2mVRD1o5Ov7TFf {\n  width: 100%;\n  font-weight: lighter;\n}", ""]);

// Exports
exports.locals = {
	"IA_counterBackground": "IA_counterBackground_s38s_gyyPB_jtSN8DYkoB",
	"IA_counterBackgroundOverlay": "IA_counterBackgroundOverlay_3B0FC42TAeQVp1TrlsSwzM",
	"IA_content": "IA_content_3nte2kHjxm8pIqCnFonFMk",
	"IA_headline": "IA_headline_1b7Y1MgD9T9X3vBcq5owxE",
	"IA_description": "IA_description_2FEaKlK9WVT1tKOIb3UM4p",
	"IA_counter": "IA_counter_3pqTAFc3f59tthmNSiM9g4",
	"IA_days": "IA_days_1ylfueJOkfBCNXRcSusm4T",
	"IA_hours": "IA_hours_rVtseAw-b4sZanQDEFGmQ",
	"IA_minuts": "IA_minuts_1BXrjrU0nLXGDqRNhPMpur",
	"IA_seconds": "IA_seconds_3-C28p54uY-058VvxYsMgo",
	"IA_number": "IA_number_cC8LftzTZQr7hb4sWO6T8",
	"IA_numberInfo": "IA_numberInfo_2qaZiFHt2mVRD1o5Ov7TFf"
};