exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_rotatorViewTemplate_18v0dZt3ns01c0aICgq76j {\n  width: 148px;\n  height: 87px;\n  float: left;\n  margin-top: 10px;\n  border-radius: 5px;\n  overflow: hidden;\n  cursor: pointer;\n  margin-right: 10px;\n  border: 1px solid rgb(221, 221, 221);\n  box-sizing: border-box;\n}\n\n.IA_template_3WW5bZSKHS_xTvwrOxhUaS {\n  float: left;\n  position: relative;\n  width: 148px;\n  height: 87px;\n}\n\n.IA_headline_34ayHttUIct_b_oH2KahRM {\n  height: 5px;\n  float: left;\n  width: calc(100% - 12px);\n  margin-top: 4px;\n  margin-left: 6px;\n  opacity: 0.6;\n}\n\n.IA_paragraphLine_xOQP-uTgZxbnfiCPQE4Q0 {\n  height: 2px;\n  float: left;\n  width: calc(100% - 12px);\n  margin-top: 4px;\n  margin-left: 6px;\n  opacity: 0.6;\n\n}", ""]);

// Exports
exports.locals = {
	"IA_rotatorViewTemplate": "IA_rotatorViewTemplate_18v0dZt3ns01c0aICgq76j",
	"IA_template": "IA_template_3WW5bZSKHS_xTvwrOxhUaS",
	"IA_headline": "IA_headline_34ayHttUIct_b_oH2KahRM",
	"IA_paragraphLine": "IA_paragraphLine_xOQP-uTgZxbnfiCPQE4Q0"
};