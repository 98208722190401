exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_authors_be5MsZqWUXA5T2OKR_eiJ {\n  width: 100%;\n  box-sizing: border-box;\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.IA_authorWrapper_3JJXwuSlMT3uFteIh5wz1 {\n  margin-right: 10px;\n  margin-bottom: 10px;\n}\n\n.IA_authorBackground_13Zw2K930Y8WdLEpLM8ANd {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  background-color: #f6f6f6;\n  border-radius: 34px;\n  padding-right: 10px;\n}\n\n.IA_authorsImageBackground_s_EYHTxR1Rf0uQpy2mC4h {\n  height: 34px;\n  width: 34px;\n  /* border-radius: 32px; */\n  position: relative;\n  /* overflow: hidden; */\n  text-align: center;\n}\n\n.IA_authorsImageText_2cXQPQrxwVfGyT3dOeH12k {\n  height: 100%;\n  width: 100%;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 28px;\n  border-radius: 100%;\n  box-sizing: border-box;\n  border: 2px solid #f6f6f6;\n}\n\n.IA_authorsImage_38zzvdaK5QXYSJQF1c4Qlr {\n  height: 100%;\n  width: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n  background-position: center;\n  background-size: cover;\n  border-radius: 100%;\n  box-sizing: border-box;\n  border: 2px solid #f6f6f6;\n}\n\n.IA_authorsDisplayName_1ShxgE_tFqqYWuy5f3S3zL {\n  margin-left: 10px;\n  margin-bottom: 2px;\n  font-weight: 600;\n  font-size: 13px;\n}", ""]);

// Exports
exports.locals = {
	"IA_authors": "IA_authors_be5MsZqWUXA5T2OKR_eiJ",
	"IA_authorWrapper": "IA_authorWrapper_3JJXwuSlMT3uFteIh5wz1",
	"IA_authorBackground": "IA_authorBackground_13Zw2K930Y8WdLEpLM8ANd",
	"IA_authorsImageBackground": "IA_authorsImageBackground_s_EYHTxR1Rf0uQpy2mC4h",
	"IA_authorsImageText": "IA_authorsImageText_2cXQPQrxwVfGyT3dOeH12k",
	"IA_authorsImage": "IA_authorsImage_38zzvdaK5QXYSJQF1c4Qlr",
	"IA_authorsDisplayName": "IA_authorsDisplayName_1ShxgE_tFqqYWuy5f3S3zL"
};