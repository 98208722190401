exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_slides_NpFp9AyUvr-mulNCxcseU {\n  position: relative;\n  width: 100%;\n  height: auto;\n  float: left;\n}\n\n.IA_filters_2ny_WNVf_dSs87B94CrUY6 {\n  width: 100%;\n  margin-bottom: 10px;\n  display: flex;\n  flex-direction: row;\n}\n\n.IA_paginationButtonContainer_1nlCAruLST8m7D-YiyzvMm {\n  width: 100%;\n  height: 30px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.IA_paginationPageCount_3Y6NcqcbJwcL2-alzgOfY3 {\n  text-align: center;\n}", ""]);

// Exports
exports.locals = {
	"IA_slides": "IA_slides_NpFp9AyUvr-mulNCxcseU",
	"IA_filters": "IA_filters_2ny_WNVf_dSs87B94CrUY6",
	"IA_paginationButtonContainer": "IA_paginationButtonContainer_1nlCAruLST8m7D-YiyzvMm",
	"IA_paginationPageCount": "IA_paginationPageCount_3Y6NcqcbJwcL2-alzgOfY3"
};