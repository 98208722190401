exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_listItem_3OMgfbQZbgz28K_wlRaxmZ {\n  position: relative;\n  width: 100%;\n  float: left;\n  box-sizing: border-box;\n}\n\n.IA_title_3pSrXj-DE9TisIBNkLfY8n {\n  float: left;\n}\n\n.IA_teaser_2h1U7hg6wxwV_C7t5dihtL {\n  float: left;\n  display: -webkit-box;\n  box-orient: vertical;\n  -webkit-box-orient: vertical;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n\n.IA_startDate_1Un-LCEm9rc886KlCdLNGg {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 10px; \n}\n\n.IA_now_2Em0ePtYVk8NnXm3FbiA-6 {\n  float: left;\n  width: 100%;\n  text-align: center;\n  font-weight: bold;\n}\n\n.IA_startDateendDateDivider_wgLZ1f1KcQ-SBEGkxqpOv {\n  float: left;\n  width: 60%;\n  height: 1px;\n  background-color: #999999;\n  margin-left: 20%;\n}\n\n.IA_endDate_1C78YbkVxMPQziE4AT04LZ {\n  float: left;\n  width: 100%;\n  text-align: center;\n}\n\n.IA_coverOverlayToday_SGSAKCBwpan3PUoqwe1DM {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 27px; \n  font-weight: bold;\n}\n\n.IA_coverOverlayMonth_3BzEGGOHB2Jff0JZT5RX1T {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 15px; \n  font-weight: normal;\n}\n\n.IA_coverOverlayDay_1Y-NYVfacSXI-pTDcLXoAn {\n  float: left;\n  width: 100%;\n  text-align: center;\n}\n\n.IA_byline_Wv_8mPRIDEjEKwEJLMew5 {\n  float: left;\n  clear: both;\n  position: relative;\n}\n", ""]);

// Exports
exports.locals = {
	"IA_listItem": "IA_listItem_3OMgfbQZbgz28K_wlRaxmZ",
	"IA_title": "IA_title_3pSrXj-DE9TisIBNkLfY8n",
	"IA_teaser": "IA_teaser_2h1U7hg6wxwV_C7t5dihtL",
	"IA_startDate": "IA_startDate_1Un-LCEm9rc886KlCdLNGg",
	"IA_now": "IA_now_2Em0ePtYVk8NnXm3FbiA-6",
	"IA_startDateendDateDivider": "IA_startDateendDateDivider_wgLZ1f1KcQ-SBEGkxqpOv",
	"IA_endDate": "IA_endDate_1C78YbkVxMPQziE4AT04LZ",
	"IA_coverOverlayToday": "IA_coverOverlayToday_SGSAKCBwpan3PUoqwe1DM",
	"IA_coverOverlayMonth": "IA_coverOverlayMonth_3BzEGGOHB2Jff0JZT5RX1T",
	"IA_coverOverlayDay": "IA_coverOverlayDay_1Y-NYVfacSXI-pTDcLXoAn",
	"IA_byline": "IA_byline_Wv_8mPRIDEjEKwEJLMew5"
};