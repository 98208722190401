exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_canteenMenu_3p6mPpehkYYvbq5W7MI4Nw {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  box-sizing: border-box;\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n}\n\n.IA_canteenMenuOverlay_24fPPa3pC3MzxEccvqC1kt {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n}\n\n.IA_headline_Bzcv_RpOtAUM-WylYAHA_ {\n  width: 100%;\n  text-align: center;\n  position: relative;\n}\n\n.IA_column_21jaN9si26y-ILUNashu9y {\n  float: left;\n  box-sizing: border-box;\n  height: 100%;\n  position: relative;\n}", ""]);

// Exports
exports.locals = {
	"IA_canteenMenu": "IA_canteenMenu_3p6mPpehkYYvbq5W7MI4Nw",
	"IA_canteenMenuOverlay": "IA_canteenMenuOverlay_24fPPa3pC3MzxEccvqC1kt",
	"IA_headline": "IA_headline_Bzcv_RpOtAUM-WylYAHA_",
	"IA_column": "IA_column_21jaN9si26y-ILUNashu9y"
};