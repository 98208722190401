exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_weather_CWbFwz6ekesQP2qPmgNdh {\n  height: 100%;\n  box-sizing: border-box;\n  display: flex;\n  justify-content: end;\n  align-items: center;\n}\n\n.IA_weatherIcon_CrJojUUz3wK-dFTlIOHwh {\n  height: 65%;\n  width: fit-content;\n}\n\n.IA_weatherTemperature_19KqF9gosAgBnE9rSHFq7i {\n  position: relative;\n  text-align: left;\n\n}\n\n.IA_weatherArea_1xRdtBYXKQa3r2IubA7vcx {\n  position: relative;\n  text-align: left;\n  font-weight: lighter;\n  clear: right;\n}", ""]);

// Exports
exports.locals = {
	"IA_weather": "IA_weather_CWbFwz6ekesQP2qPmgNdh",
	"IA_weatherIcon": "IA_weatherIcon_CrJojUUz3wK-dFTlIOHwh",
	"IA_weatherTemperature": "IA_weatherTemperature_19KqF9gosAgBnE9rSHFq7i",
	"IA_weatherArea": "IA_weatherArea_1xRdtBYXKQa3r2IubA7vcx"
};