exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_companyInformation_24I_Z14wdfBKl8n95v81zo {\n  width: 100%;\n  margin-top: 20px;\n  margin-bottom: 20px;\n  float: left;\n}\n\n.IA_divider_Ib1-JpyGiBCRk-Nt4Qn-Q, .IA_dividerDarkMode_sox2I47g67-Fv15Pxw1CS {\n  height: 1px;\n  width: 100%;\n  background-color: #eeeeee;\n  margin-top: 40px;\n  margin-bottom: 20px;\n  float: left;\n}\n\n.IA_billingInfo_3xqoBpjdfviIm1-an2V0lf {\n  font-size: 14px;\n}", ""]);

// Exports
exports.locals = {
	"IA_companyInformation": "IA_companyInformation_24I_Z14wdfBKl8n95v81zo",
	"IA_divider": "IA_divider_Ib1-JpyGiBCRk-Nt4Qn-Q",
	"IA_dividerDarkMode": "IA_dividerDarkMode_sox2I47g67-Fv15Pxw1CS",
	"IA_billingInfo": "IA_billingInfo_3xqoBpjdfviIm1-an2V0lf"
};